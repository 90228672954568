<template>
  <div class="treat_detail">
    <v-card flat style="margin-top: 20px">
      <v-card-text class="view_data">
        <v-overlay absolute dark :value="overlay"> </v-overlay>
        <!-- start -->
        <v-row>
          <v-col cols="12" sm="4">
            <v-sheet outlined rounded>
              <v-card-actions>
                <div class="myleft mytitle">urine</div>
                <v-spacer></v-spacer>
                <div class="myright">
                  <v-icon :color="view.urine ? 'blue' : 'red'">{{
                    view.urine ? "mdi-check" : "mdi-close"
                  }}</v-icon>
                </div>
              </v-card-actions>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="4">
            <v-sheet outlined rounded>
              <v-card-actions>
                <div class="myleftmytitle">tissue</div>
                <v-spacer></v-spacer>
                <v-icon :color="view.tissue ? 'blue' : 'red'">{{
                  view.tissue ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </v-card-actions>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="4">
            <v-sheet outlined rounded>
              <v-card-actions>
                <div class="myleft mytitle">swab</div>
                <v-spacer></v-spacer>
                <v-icon :color="view.swab ? 'blue' : 'red'">{{
                  view.swab ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </v-card-actions>
            </v-sheet>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="12" sm="4">
            <v-sheet outlined rounded>
              <v-card-actions>
                <div class="myleft mytitle">sputim</div>
                <v-spacer></v-spacer>
                <v-icon :color="view.sputim ? 'blue' : 'red'">{{
                  view.sputim ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </v-card-actions>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="4">
            <v-sheet outlined rounded>
              <v-card-actions>
                <div class="myleft mytitle">fluids</div>
                <v-spacer></v-spacer>
                <v-icon :color="view.fluids ? 'blue' : 'red'">{{
                  view.fluids ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </v-card-actions>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="4">
            <v-sheet outlined rounded>
              <v-card-actions>
                <div class="myleft mytitle">faeces</div>
                <v-spacer></v-spacer>
                <v-icon :color="view.faeces ? 'blue' : 'red'">{{
                  view.faeces ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </v-card-actions>
            </v-sheet>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="12" sm="4">
            <v-sheet outlined rounded>
              <v-card-actions>
                <div class="myleft mytitle">blood</div>
                <v-spacer></v-spacer>
                <v-icon :color="view.blood ? 'blue' : 'red'">{{
                  view.blood ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </v-card-actions>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="4">
            <v-sheet outlined rounded>
              <v-card-actions>
                <div class="myleft mytitle">cytology</div>
                <v-spacer></v-spacer>
                <v-icon :color="view.cytology ? 'blue' : 'red'">{{
                  view.cytology ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </v-card-actions>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="4">
            <v-sheet outlined rounded>
              <v-card-actions>
                <div class="myleft mytitle">urgency</div>
                <v-spacer></v-spacer>
                <v-icon :color="view.urgency ? 'blue' : 'red'">{{
                  view.urgency ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </v-card-actions>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="4">
            <v-sheet outlined rounded>
              <v-card-actions>
                <div class="myleft mytitle">fasting</div>
                <v-spacer></v-spacer>
                <v-icon :color="view.fasting ? 'blue' : 'red'">{{
                  view.fasting ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </v-card-actions>
            </v-sheet>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="12" sm="4">
            <v-card outlined>
              <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                drug therapy
              </v-card-subtitle>
              <v-card-text>
                {{ view.drug_therapy }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card outlined>
              <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                last Dose
              </v-card-subtitle>
              <v-card-text>
                {{ view.last_dose }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card outlined>
              <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                last Dose Date
              </v-card-subtitle>
              <v-card-text>
                {{ view.last_dose_date_time | myDate }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col cols="12" sm="4">
            <v-card outlined>
              <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                biochemistry
              </v-card-subtitle>
              <v-card-text>
                {{ view.biochemistry }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card outlined>
              <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                cervical Cytology
              </v-card-subtitle>
              <v-card-text>
                {{ view.cervical_cytology }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card outlined>
              <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                profile Test
              </v-card-subtitle>
              <v-card-text>
                {{ view.profile_test }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-card outlined>
              <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                hematology
              </v-card-subtitle>
              <v-card-text>
                {{ view.hematology }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card outlined>
              <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                microbiology
              </v-card-subtitle>
              <v-card-text>
                {{ view.microbiology }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card outlined>
              <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                anatomical Pathology
              </v-card-subtitle>
              <v-card-text>
                {{ view.anatomical_pathology }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-card outlined>
              <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                additional Test
              </v-card-subtitle>
              <v-card-text>
                {{ view.additional_test }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card outlined>
              <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                anatomical Pathology
              </v-card-subtitle>
              <v-card-text>
                {{ view.anatomical_pathology }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- end -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Utils from "../../../mixins/utils";
import Restful from "@/services/RestFul";
export default {
  props: {
    medavId: {
      type: String,
    },
  },
  mixins: [Utils],
  data: () => ({
    overlay: true,
    absolute: true,
    view: {},
  }),
  watch: {
    medavId: function (v) {
      let d = this._decode(v);
      console.log("d:", d);
      this.list();
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.overlay = true;
      let self = this;
      let d = this._decode(this.medavId);
      console.log("dis:", d);
      Restful.medics.treatment.labs
        .list(d)
        .then((response) => {
          console.log(response.data);
          this.view = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.overlay = false;
        });
    },
  },
};
</script>
