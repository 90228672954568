<template>
  <div>
    <v-dialog v-model="enquiryDialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          {{ name }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="mediList"
            :loading="loading"
            :sort-by="['id']"
            :sort-desc="[true]"
            loader-height="1"
          >
            <template v-slot:item.drug="{ item }">
              {{ item.drug | capitalize }} ( {{ item.strength }})
            </template>
            <template v-slot:item.brand_name="{ item }">
              {{ item.brand_name | capitalize }}
            </template>

            <template v-slot:item.price="{ item }">
              {{ currency(item.price) }}
            </template>
            <template v-slot:item.cost="{ item }">
              {{ currency(item.cost) }}
            </template>
            <template v-slot:item.manufacturer="{ item }">
              {{ item.manufacturer | capitalize }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="red" small text @click="closeDialog" v-on="on">
                cancel
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Utils from "../../../mixins/utils.js";
// import Restful from "../../../services/RestFul.js";

export default {
  props: {
    enquiryDialog: Boolean,
    myData: { type: String },
    myId: { type: Number },
    name: { type: String },
  },
  mixins: [Utils],
  data: () => ({
    loading: false,
    mediList: [],
    headers: [{ text: "name", value: "name" }],
  }),
  created() {
    this.find();
  },
  methods: {
    find() {
      let me = JSON.parse(this.myData);
      console.log("me", me);
      this.mediList = me;
      // this.loading = true;
      // let medics_available_timesid = this.medic;
      // let subscriberSubsidiariesid = this.myId;
      // let data = { medics_available_timesid, subscriberSubsidiariesid };
      // console.log("data", data);
      // let self = this;
      // Restful.medics.treatment.transact.prospect.prescribe
      //   .detailMedic(data)
      //   .then((response) => {
      //     console.log(response.data);
      //     this.mediList = response.data;
      //     this.loading = false;
      //   })
      //   .catch((error) => {
      //     console.log(error.response);
      //     self.loading = false;
      //   });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeEnquiry", payload);
    },
  },
};
</script>
