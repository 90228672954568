<template>
  <div class="treat_detail">
    <v-card flat style="margin-top: 20px">
      <v-card-text>
        <v-overlay absolute dark :value="overlay"> </v-overlay>

        <div style="padding-left: 5px">
          <v-card outlined flat>
            <v-card-title class="caption">main problem</v-card-title>
            <v-card-text>
              {{ view.main_problem }}
            </v-card-text>
          </v-card>
        </div>
        <div style="padding-left: 5px; margin: 7px 0px">
          <v-card outlined flat>
            <v-card-title class="caption">drug details</v-card-title>
            <v-card-text>
              {{ view.drug_details }}
            </v-card-text>
          </v-card>
        </div>

        <div class="mylist">
          <ul>
            <li>
              <div class="myleft">drugs</div>
              <div class="myright">
                {{ view.drugs ? "Yes" : "No" }}
              </div>
              <div class="clearall"></div>
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Utils from "../../../mixins/utils";
import Restful from "@/services/RestFul";
export default {
  props: {
    medavId: {
      type: String,
    },
  },
  mixins: [Utils],
  data: () => ({
    overlay: false,
    absolute: true,
    view: {},
  }),
  watch: {
    medavId: function (v) {
      let d = this._decode(v);
      console.log("d:", d);
      this.list();
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.overlay = true;
      let d = this._decode(this.medavId);
      console.log("dis:", d);
      let self = this;
      Restful.medics.treatment.history
        .list(d)
        .then((response) => {
          console.log(response.data);
          this.view = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.overlay = false;
        });
    },
  },
};
</script>
