<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs :items="crumbs" exact>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" icon @click="goBack">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Go Back</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-row>
        <v-col cols="12" sm="4">
          <v-card outlined>
            <v-card-text>
              <v-autocomplete
                x-large
                label="Search for Patient"
                :items="patientList"
                item-key="id"
                item-text="patient"
                v-model="patientId"
                append-outer-icon="mdi-magnify"
                @click:append-outer="search"
                return-object
              ></v-autocomplete>
            </v-card-text>
          </v-card>

          <!-- style="height: 400px !important;" -->

          <v-card
            outlined
            style="margin: 4px 0"
            flat
            v-show="historyList.length > 0"
          >
            <v-toolbar dense flat>
              <v-toolbar-title style="color: rgb(0, 116, 0)">
                {{ result.patient | capitalize }}
              </v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- feffee-->
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="padding: 0">
              <v-list three-line shaped :max-height="myHeight" class="scroll">
                <v-list-item-group v-model="selectedItem" color="primary">
                  <v-list-item
                    v-for="item in historyList"
                    :key="item.id"
                    @click="details(item.medicsAvailableTimes)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.location }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <div
                          style="
                            padding-bottom: 4px;
                            font-size: 1.1em;
                            font-weight: 500;
                          "
                        >
                          {{ item.date | custDate }}
                        </div>
                        {{ item.starts | myTime }} to {{ item.ends | myTime }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn color="primary" icon>
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8">
          <div :style="{ height: myHeight }">
            <v-tabs v-model="tabs" background-color="white" grow show-arrows>
              <v-tab
                :disabled="historyList.length > 0 ? false : true"
                key="initial"
              >
                initial complaint
              </v-tab>
              <v-tab
                :disabled="historyList.length > 0 ? false : true"
                key="diagnostics"
              >
                diagnostics
              </v-tab>
              <v-tab
                :disabled="historyList.length > 0 ? false : true"
                key="history"
              >
                history
              </v-tab>
              <v-tab
                :disabled="historyList.length > 0 ? false : true"
                key="enquiry"
              >
                enquiry
              </v-tab>
              <v-tab
                :disabled="historyList.length > 0 ? false : true"
                key="prescribe"
              >
                prescriptions
              </v-tab>
              <v-tab
                :disabled="historyList.length > 0 ? false : true"
                key="labs"
              >
                laboratory
              </v-tab>
              <v-tab
                :disabled="historyList.length > 0 ? false : true"
                key="summary"
              >
                Summary
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item key="initial">
                <DetailInitialize :medavId="_encode(medavId)" />
              </v-tab-item>
              <v-tab-item key="diagnostics">
                <DetailDiagnostics :medavId="_encode(medavId)" />
              </v-tab-item>
              <v-tab-item key="history">
                <DetailHistory :medavId="_encode(medavId)" />
              </v-tab-item>
              <v-tab-item key="enquiry">
                <DetailEnquiry :medavId="_encode(medavId)" />
              </v-tab-item>
              <v-tab-item key="prescribe">
                <DetailPrescription :medavId="_encode(medavId)" />
              </v-tab-item>
              <v-tab-item key="labs">
                <DetailLaboratory :medavId="_encode(medavId)" />
              </v-tab-item>
              <v-tab-item key="summary">
                <DetailSummary :medavId="_encode(medavId)" />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import WinScroll from "../../mixins/windowscroll";
import Restful from "@/services/RestFul";
import Utils from "../../mixins/utils";
import DetailInitialize from "./treat_his_detail/initialize.vue";
import DetailDiagnostics from "./treat_his_detail/diagnostics.vue";
import DetailHistory from "./treat_his_detail/history.vue";
import DetailEnquiry from "./treat_his_detail/enquiry.vue";
import DetailPrescription from "./treat_his_detail/prescription.vue";
import DetailLaboratory from "./treat_his_detail/lab.vue";
import DetailSummary from "./treat_his_detail/summary.vue";

export default {
  components: {
    DetailInitialize,
    DetailDiagnostics,
    DetailHistory,
    DetailEnquiry,
    DetailPrescription,
    DetailLaboratory,
    DetailSummary,
  },
  mixins: [WinScroll("position"), Utils],
  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "medics.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Treatment History",
          to: { name: "medics.histreat" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      selectedItem: null,
      tabs: null,
      patientList: [],
      patientId: null,
      result: {},
      historyList: [],
      medavId: 0,
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
    myHeight: function () {
      let h = this.$vuetify.breakpoint.height - 270;
      console.log("h", h);
      return h;
    },
  },
  created() {
    this.patients();
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    patients() {
      Restful.medics.patient
        .appointmentsDone()
        .then((response) => {
          console.log(response.data);
          this.patientList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    search() {
      let give = this.patientId;
      this.result = give;
      let user_accountsid = give.id;
      let data = { user_accountsid };
      console.log("data: ", data);
      Restful.medics.patient.request
        .history(data)
        .then((response) => {
          console.log(response.data);
          this.historyList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    details(id) {
      console.log(id);
      this.medavId = id;
    },
    menulink() {
      this.$emit("sidebar");
    },
  },
};
</script>
