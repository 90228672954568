<template>
  <div class="treat_detail">
    <v-card flat style="margin-top: 20px">
      <v-card-text>
        <v-overlay absolute dark :value="overlay"> </v-overlay>

        <v-tabs v-model="tabs" grow>
          <v-tab key="detail">initial complaint</v-tab>
          <v-tab key="more_info">more info</v-tab>
          <v-tab key="evidence">evidence</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item key="detail" style="padding-top: 15px">
            <div style="padding-left: 5px; margin: 7px 0px">
              <v-card outlined flat>
                <v-card-title class="caption">initial complaint</v-card-title>
                <v-card-text>
                  {{ complain.initial_complaints }}
                </v-card-text>
              </v-card>
            </div>
          </v-tab-item>
          <v-tab-item key="more_info" style="padding-top: 15px">
            <v-data-table
              :loading="loading"
              loader-height="1"
              :headers="headers"
              :sort-desc="[true]"
              :items="moreInfos"
              :items-per-page="10"
            ></v-data-table>
          </v-tab-item>
          <v-tab-item key="evidence" style="padding-top: 15px">
            <v-data-iterator :items="evidenceList">
              <template v-slot:default="props">
                <v-row>
                  <v-col
                    v-for="item in props.items"
                    :key="item.id"
                    cols="12"
                    sm="4"
                    md="4"
                    lg="4"
                  >
                    <v-card flat outlined>
                      <v-img
                        height="350"
                        :src="pix(item.evidence)"
                        :title="item.description"
                      ></v-img>
                      <v-card-text class="card_list">
                        <ul>
                          <li>
                            <div class="myleft">information type</div>
                            <div class="myright">
                              {{
                                truncateString(item.information_typeName, 22)
                              }}
                            </div>
                            <div class="clearall"></div>
                          </li>
                          <li>
                            <div class="myleft">description</div>
                            <div class="myright">
                              {{ truncateString(item.description, 25) }}
                            </div>
                            <div class="clearall"></div>
                          </li>
                          <li>
                            <div class="myleft">submitted</div>
                            <div class="myright">
                              {{ item.created | myDating }}
                            </div>
                            <div class="clearall"></div>
                          </li>
                        </ul>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Utils from "../../../mixins/utils";
import Restful from "@/services/RestFul";
export default {
  props: {
    medavId: {
      type: String,
    },
  },
  mixins: [Utils],
  data: () => ({
    loading: false,
    tabs: null,
    overlay: true,
    absolute: true,
    complain: {},
    moreInfos: [],
    headers: [
      { text: "allergies", value: "allergies" },
      { text: "medics_request", value: "medics_request" },
      { text: "routine_drugs", value: "routine_drugs", align: "center" },
    ],
    evidenceList: [],
  }),
  watch: {
    medavId: function (v) {
      let d = this._decode(v);
      console.log("d:", d);
      this.complaint();
      this.moreInfo();
      this.evidence();
    },
  },
  created() {
    this.complaint();
    this.moreInfo();
    this.evidence();
  },
  methods: {
    complaint() {
      this.overlay = true;
      let self = this;
      let d = this._decode(this.medavId);
      console.log("dis:", d);
      Restful.medics.patient.initial
        .complaints(d)
        .then((response) => {
          console.log(response.data);
          this.complain = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.overlay = false;
        });
    },
    moreInfo() {
      this.moreInfos = [];
      this.overlay = true;
      let self = this;
      let d = this._decode(this.medavId);
      console.log("dis:", d);
      Restful.medics.patient.initial
        .more(d)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.moreInfos = response.data;
            this.overlay = false;
          } else if (response.status === 204) {
            this.moreInfos = [];
            this.overlay = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.overlay = false;
        });
    },
    evidence() {
      this.moreInfos = [];
      this.overlay = true;
      let self = this;
      let d = this._decode(this.medavId);
      console.log("d", d);
      Restful.medics.evidence
        .list(d)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.evidenceList = response.data;
            this.overlay = false;
          } else if (response.status === 204) {
            this.evidenceList = [];
            this.overlay = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.overlay = false;
          self.overlay = false;
        });
    },
    pix(img) {
      if (img === "") {
        return "";
      } else {
        return img;
      }
    },
  },
};
</script>
