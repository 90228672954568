<template>
  <div class="treat_detail">
    <v-card flat style="margin-top: 20px">
      <v-card-text>
        <v-overlay absolute dark :value="overlay"> </v-overlay>
        <v-data-table :headers="headers" :items="view" :items-per-page="5">
          <template v-slot:item.sys_category_detailsName="{ item }">
            {{ item.sys_category_detailsName }}
          </template>
          <template v-slot:item.details="{ item }">
            <v-btn
              text
              x-small
              color="primary"
              @click.stop="$set(enquiryDialog, item.id, true)"
            >
              detail
              <EnquiryDetail
                v-if="enquiryDialog[item.id]"
                :enquiryDialog="enquiryDialog[item.id]"
                :myData="JSON.stringify(item.details)"
                :name="item.name"
                :myId="item.id"
                @closeEnquiry="closeEnquiry"
              />
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Utils from "../../../mixins/utils";
import Restful from "@/services/RestFul";
import EnquiryDetail from "../../../components/appointments/treathistory/enquiryDetail.vue";
export default {
  props: {
    medavId: {
      type: String,
    },
  },
  components: { EnquiryDetail },
  mixins: [Utils],
  data: () => ({
    overlay: false,
    absolute: true,
    enquiryDialog: {},
    view: [],
    headers: [
      { text: "name", value: "name" },
      { text: "active", value: "active" },
      { text: "details", value: "details" },
    ],
  }),
  watch: {
    medavId: function (v) {
      let d = this._decode(v);
      console.log("d:", d);
      this.list();
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.overlay = true;
      let self = this;
      let d = this._decode(this.medavId);
      console.log("dis:", d);
      Restful.medics.treatment.enquiry
        .list(d)
        .then((response) => {
          console.log("enquiry", response.data);
          this.view = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.overlay = false;
        });
    },
    closeEnquiry(p) {
      this.$set(this.enquiryDialog, p.id, p.state);
    },
  },
};
</script>
